import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar.jsx";
import PrivateRouter from "./router/PrivateRouter/index.jsx";
import LoginPage from "./AdminPages/LoginPage/index.jsx";
import Dashboard from "./AdminPages/Dashboard/index.jsx";

const Map = React.lazy(() => import("./components/Map/index.jsx"));
const Products = React.lazy(() => import("./components/Products/Products"));
const AboutUs = React.lazy(() => import("./components/AboutUs/AboutUs.jsx"));
const ProductDetails = React.lazy(() => import("./components/ProductDetails"));

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contacts" element={<Map />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route
            path="/admin/dashboard/*"
            element={
              <PrivateRouter>
                <Dashboard />
              </PrivateRouter>
            }
          />

          <Route path="/admin/login" element={<LoginPage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
