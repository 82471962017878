import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAddFileMutation } from "../../../../../api/FilesApi";
import { IoIosCloseCircle } from "react-icons/io";

function CertificateForm() {
  const [addFile] = useAddFileMutation();
  const [image, setImage] = useState("");

  const showCustomAlert = () => {
    const modal = document.getElementById("customAlertForm");
    const closeBtn = document.getElementsByClassName("close")[0];

    modal.style.display = "block";

    closeBtn.onclick = function () {
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  };

  const showCustomAlertEmptyInput = () => {
    const modal = document.getElementById("customAlertFormEmptyInp");
    const closeBtn = document.getElementsByClassName("close")[0];

    modal.style.display = "block";

    closeBtn.onclick = function () {
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  };

  const addFileImg = async (e) => {
    e.preventDefault();

    if (!image) {
      showCustomAlertEmptyInput();
      return;
    }

    const formData = new FormData();
    formData.append("image", image);

    try {
      const response = await addFile(formData).unwrap();
      showCustomAlert();
      setImage(""); 
    } catch (err) {
      console.error("Error: ", err);
      alert(
        "Upload failed: " +
          (err?.data?.message || err?.error || "Unknown error")
      );
    }
  };

  return (
    <>
      <div id="customAlertForm" className="modal">
        <div className="modal-content">
          <span className="close">
            <IoIosCloseCircle />
          </span>
          <p>File added successfully</p>
        </div>
      </div>

      <div id="customAlertFormEmptyInp" className="modal">
        <div className="modal-content bg-danger">
          <span className="close">
            <IoIosCloseCircle />
          </span>
          <p>Empty Input</p>
        </div>
      </div>
      <Form onSubmit={addFileImg}>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Certificate Image</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </Form.Group>

        <Button type="submit">Add Certificate</Button>
      </Form>
    </>
  );
}

export default CertificateForm;
