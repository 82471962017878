import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { useGetSectionQuery } from "../../../api/HomeSection";
import s from "./Section.module.scss";

const SectionBlock = ({ image, title, description, isImageLeft }) => (
  <Row className="d-flex justify-content-center mt-5">
    {isImageLeft ? (
      <>
        <Col md="4">
          <div className={s.SectionTopLeft}>
            <img src={`https://merit.emilgasarayev.com${image}`} alt="img" />
          </div>
        </Col>
        <Col md="4" style={{ margin: "auto" }}>
          <div className={s.SectionTopRight}>
            <p className={s.brandtext}>Merit-Brand</p>
            <h1>{title}</h1>
            <p className={s.sectionMain__text}>{description}</p>
          </div>
        </Col>
      </>
    ) : (
      <>
        <Col md="4" style={{ margin: "auto" }}>
          <div className={s.SectionTopRight}>
            <p className={s.brandtext}>Merit-Brand</p>
            <h1>{title}</h1>
            <p className={s.sectionMain__text}>{description}</p>
          </div>
        </Col>
        <Col md="4">
          <div className={s.SectionTopLeft}>
            <img src={`https://merit.emilgasarayev.com${image}`} alt="img" />
          </div>
        </Col>
      </>
    )}
  </Row>
);

function SectionMain() {
  const { t, i18n } = useTranslation();

  const section1 = useGetSectionQuery({
    key: "section1",
    lang: i18n.language,
  });
  const section2 = useGetSectionQuery({
    key: "section2",
    lang: i18n.language,
  });
  const section3 = useGetSectionQuery({
    key: "section3",
    lang: i18n.language,
  });
  const section4 = useGetSectionQuery({
    key: "section4",
    lang: i18n.language,
  });

  const isLoading =
    section1.isLoading ||
    section2.isLoading ||
    section3.isLoading ||
    section4.isLoading;

  const hasError =
    section1.error || section2.error || section3.error || section4.error;

  if (isLoading) return <div>Loading...</div>;
  if (hasError) return <div>Error loading section</div>;

  return (
    <div className={s.Section}>
      <div className={s.SectionTop}>
        <Container>
          <SectionBlock
            image={section1.data?.image}
            title={
              section1.data?.translations?.[i18n.language]?.title ||
              t("section1Title")
            }
            description={
              section1.data?.translations?.[i18n.language]?.description ||
              t("section1Desc")
            }
            isImageLeft={true}
          />
          <SectionBlock
            image={section2.data?.image}
            title={
              section2.data?.translations?.[i18n.language]?.title ||
              t("section2Title")
            }
            description={
              section2.data?.translations?.[i18n.language]?.description ||
              t("section2Desc")
            }
            isImageLeft={false}
          />
        </Container>
      </div>

      <div className="mt-5 mb-5">
        <Container>
          <Row>
            <Col md={6}>
              <div className={s.sectionMain__img}>
                <img
                  src={`https://merit.emilgasarayev.com${section3.data?.image}`}
                  alt="img"
                />
              </div>
            </Col>
            <Col md={6} style={{ margin: "auto" }}>
              <div className={s.sectionMain}>
                <p className={s.brandtext}>Merit-Brand</p>
                <h1 className={s.sectionMain__title}>
                  {section3.data?.translations?.[i18n.language]?.title ||
                    t("lido")}
                </h1>
                <p className={s.sectionMain__text}>
                  {section3.data?.translations?.[i18n.language]?.description ||
                    t("lido1")}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6} style={{ margin: "auto" }}>
              <div className={s.sectionMain}>
                <p className={s.brandtext}>Merit-Brand</p>
                <h1 className={s.sectionMain__title}>
                  {section4.data?.translations?.[i18n.language]?.title ||
                    t("goldshabran")}
                </h1>
                <p className={s.sectionMain__text}>
                  {section4.data?.translations?.[i18n.language]?.description ||
                    t("goldshabran1")}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className={s.sectionMain__img}>
                <img
                  src={`https://merit.emilgasarayev.com${section4.data?.image}`}
                  alt="img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SectionMain;
