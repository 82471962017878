import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { IoTrashOutline } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { useGetFilesQuery, useDeleteFileMutation } from "../../../../../api/FilesApi";

function CertificateTable() {
  const { data } = useGetFilesQuery();
  const [deleteFile, { isLoading, isSuccess }] = useDeleteFileMutation();
  const [showModal, setShowModal] = useState(false);

  const deleteshowCustomAlert = () => {
    setShowModal(true);
    setTimeout(() => setShowModal(false), 2000);
  };

  const deleteFileHandler = async (_id) => {
    if (!_id) {
      return;
    }
    try {
      await deleteFile(_id).unwrap(); 
      deleteshowCustomAlert();
    } catch (err) {
      console.error("Error! File not deleted", err);
    }
  };

  return (
    <>
      <div id="customAlertdelete" className="modal" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-content modal-content-delete">
          <span className="close" onClick={() => setShowModal(false)}>
            <IoIosCloseCircle />
          </span>
          <p>{isSuccess ? "File deleted successfully" : "Error deleting file"}</p>
        </div>
      </div>

      <Table bordered hover>
        <thead>
          <tr>
       
            <th>Image</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((file, index) => (
            <tr key={index}>
          
              <td className="pt-5 pb-5">
                {file.image !== "No img" ? (
                  <img
                    src={`https://merit.emilgasarayev.com${file.image}`}
                    alt="Certificate"
                    style={{ width: "600px", height: "400px" }}
                  />
                ) : (
                  "No img"
                )}
              </td>
              <td>
                <div className="btn_product_ctgry">
                  <button
                    className="btn_product btn_product_remove"
                    onClick={() => {
                      deleteFileHandler(file._id);
                    }}
                    disabled={isLoading} 
                  >
                    <IoTrashOutline />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default CertificateTable;
