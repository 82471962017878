import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { SliderApi } from "./../api/SliderApi.js";

const store = configureStore({
  reducer: {
    [SliderApi.reducerPath]: SliderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([SliderApi.middleware]),
});

setupListeners(store.dispatch);
export default store;
