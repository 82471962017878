import { baseQuery } from "./api";

export const SliderApi = baseQuery.injectEndpoints({
  tagTypes: ["Slider"],
  endpoints: (builder) => ({
    getSlider: builder.query({
      query: () => ({
        url: "/api/sliders",
        headers: {
          Accept: "application/json",
        },
      }),
      providesTags: ["Slider"],
    }),

    addSlider: builder.mutation({
      query: (slider) => ({
        url: `/api/sliders`,
        method: "POST",
        body: slider,
        headers: {
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["Slider"],
    }),

    deleteSlider: builder.mutation({
      query: (id) => ({
        url: `/api/sliders/${id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["Slider"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSliderQuery,
  useAddSliderMutation,
  useDeleteSliderMutation,
} = SliderApi;
