import React from "react";
import Table from "react-bootstrap/Table";
import { IoTrashOutline } from "react-icons/io5";
import { useDeleteCategoryMutation, useGetCategoryQuery } from "../../../../../api/CategoryApi";
import "./style.css";

function CategoryTable() {
  const { data } = useGetCategoryQuery();
  const [removeCategory] = useDeleteCategoryMutation();

  const deleteCategory = async (categoryId) => {
    try {
      await removeCategory(categoryId).unwrap();
      alert("Data deleted successfully");
    } catch (err) {
      alert("Error! Category could not be deleted.");
    }
  };

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>English</th>
          <th>Azerbaijani</th>
          <th>Russian</th>
          <th>Remove</th>
        </tr>
      </thead>
      <tbody>
        {data?.categories?.map((ctgry) => (
          <tr key={ctgry._id}>
            <td>{ctgry._id}</td>
            <td>{ctgry.name.en}</td>
            <td>{ctgry.name.az}</td>
            <td>{ctgry.name.ru}</td>
            <td>
              <button
                onClick={() => deleteCategory(ctgry._id)}
                className="btn_product btn_product_remove"
              >
                <IoTrashOutline />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default CategoryTable;
