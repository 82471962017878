import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import img from "./../../img/logofooter.png";
import s from "./Footer.module.scss";

function Footer() {
  return (
    <div className={s.Footer}>
      <Container>
        <Row>
          <Col md={6} className="m-auto">
            <div className={s.FooterLeft}>
              <img className={s.img} src={img} alt="" />
            </div>
          </Col>
        </Row>
        <Row>
          <div className="mt-2">
            <p>
              Created by {" "}
              <a
                href="https://shaftali.az/"
                target="_blank"
                rel="noopener noreferrer"
                style={{textDecoration:"none", color:"rgb(199, 168, 113)"}}
              >
                Shaftali.az
              </a>
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
