import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactQuill from "react-quill";  
import { useGetProductsQuery, useEditProductsMutation, useDeleteProductsMutation } from "../../../../../api/ProductsApi";
import { useGetCategoryQuery } from "../../../../../api/CategoryApi";
import "react-quill/dist/quill.snow.css";  
import "./style.css";

function ProductTable() {
  const { data } = useGetProductsQuery();
  const { data: categoryData } = useGetCategoryQuery();
  const [editProduct] = useEditProductsMutation();
  const [deleteProduct] = useDeleteProductsMutation();
  const languages = ["az", "en", "ru"];

  const [show, setShow] = useState(false);
  const [selectProduct, setSelectProduct] = useState(null);
  const [newProductName, setNewProductName] = useState({});
  const [newProductSubtitle, setNewProductSubtitle] = useState({});
  const [newProductCategory, setNewProductCategory] = useState("");
  const [newProductText, setNewProductText] = useState({});
  const [newProductImage, setNewProductImage] = useState(null);
  const [selectedLang] = useState("az");

  const handleClose = () => {
    setShow(false);
    setNewProductName({});
    setNewProductSubtitle({});
    setNewProductCategory("");
    setNewProductText({});
    setNewProductImage(null);
  };

  const handleShow = (product) => {
    setSelectProduct(product);
    setNewProductName(product.title || {});
    setNewProductSubtitle(product.subtitle || {});
    setNewProductCategory(product.categoryId?._id || "");
    setNewProductText(product.description || {});
    setNewProductImage(product.image || "");
    setShow(true);
  };

  const handleSaveChanges = async () => {
    if (!selectProduct) return;

    const updatedProduct = {
      id: selectProduct._id,
      title: newProductName,
      subtitle: newProductSubtitle,
      categoryId: newProductCategory,
      description: newProductText,
    };

    if (newProductImage && typeof newProductImage === "object") {
      const formData = new FormData();
      formData.append("title", JSON.stringify(newProductName));
      formData.append("subtitle", JSON.stringify(newProductSubtitle));
      formData.append("categoryId", newProductCategory);
      formData.append("description", JSON.stringify(newProductText));
      formData.append("image", newProductImage);

      await editProduct({ id: selectProduct._id, body: formData });
    } else {
      await editProduct(updatedProduct);
    }

    handleClose();
  };

  const handleDelete = async (productId) => {
    if (window.confirm("Bu məhsulu silmək istədiyinizə əminsiniz?")) {
      await deleteProduct(productId);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop={false} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {languages.map((lang) => (
              <Form.Group className="form-group-custom" key={lang}>
                <Form.Label>Product Title ({lang.toUpperCase()})</Form.Label>
                <Form.Control
                  type="text"
                  value={newProductName[lang] || ""}
                  onChange={(e) =>
                    setNewProductName({
                      ...newProductName,
                      [lang]: e.target.value,
                    })
                  }
                />
                <Form.Label>Subtitle ({lang.toUpperCase()})</Form.Label>
                <Form.Control
                  type="text"
                  value={newProductSubtitle[lang] || ""}
                  onChange={(e) =>
                    setNewProductSubtitle({
                      ...newProductSubtitle,
                      [lang]: e.target.value,
                    })
                  }
                />
                <Form.Label>
                  Product Description ({lang.toUpperCase()})
                </Form.Label>
               
                <ReactQuill
                  value={newProductText[lang] || ""}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    color: "black"
                  }}
                  onChange={(value) =>
                    setNewProductText({
                      ...newProductText,
                      [lang]: value,
                    })
                    
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      [{ align: [] }],
                      ["link"],
                      ["blockquote"],
                      [{ indent: "-1" }, { indent: "+1" }],
                      
                    ],
                  }}
                />
              </Form.Group>
            ))}

            <Form.Group className="form-group-custom">
              <Form.Label>Product Image</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setNewProductImage(e.target.files[0])}
              />
              {newProductImage && typeof newProductImage !== "object" && (
                <img
                  src={`https://merit.emilgasarayev.com${newProductImage}`}
                  alt="Current product"
                  style={{ width: "100px", height: "100px", marginTop: "10px" }}
                />
              )}
            </Form.Group>

            <Form.Select
              onChange={(e) => setNewProductCategory(e.target.value)}
              value={newProductCategory}
              className="form-group-custom"
            >
              <option>Select category...</option>
              {categoryData?.categories?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.name?.[selectedLang] || item.name?.az || "Unknown"}
                </option>
              ))}
            </Form.Select>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Table bordered hover>
        <thead>
          <tr>
            <th>Language</th>
            <th>Title</th>
            <th>Subtitle</th>
            <th>Description</th>
            <th>Category</th>
            <th>Image</th>
            <th>Edit/Delete</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data
              .filter((product) => product.title?.az)
              .map((product) => (
                <tr key={product._id}>
                  <td>Azerbaijan</td>
                  <td>{product.title?.az}</td>
                  <td>{product.subtitle?.az || "Melumat yoxdu"}</td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html:
                        product.description?.az || "No description available",
                    }}
                  ></td>

                  <td>{product.categoryId?.name?.az || "No Category"}</td>
                  <td>
                    <img
                      src={`https://merit.emilgasarayev.com${product.image}`}
                      alt={product.title?.az}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </td>
                  <td className="product_table_btn">
                    <button
                      onClick={() => handleShow(product)}
                      className="btn_product btn_product_edit"
                    >
                      <FaRegEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(product._id)}
                      className="btn_product btn_product_remove"
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </>
  );
}

export default ProductTable;
