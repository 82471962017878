import React, { useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Carousel, Spinner, Alert } from "react-bootstrap";
import { useGetSliderQuery } from "../../../api/SliderApi.js";
import s from "./Slider.module.scss";

function Slider() {
  const { data, error, isLoading } = useGetSliderQuery();

  const sliderItems = useMemo(() => {
    if (!data?.length) return null;
    return data.map((slider,id) => (
      <Carousel.Item key={id}>
        <img
          className="d-block w-100"
          src={`https://merit.emilgasarayev.com${slider.image}`}
          alt="Slider"
        />
        {slider.caption && <Carousel.Caption>{slider.caption}</Carousel.Caption>}
      </Carousel.Item>
    ));
  }, [data]);

  return (
    <div className={s.main}>
      <Container fluid className={s.container}>
        <Row className={s.row}>
          <Col lg={12} className="p-0">
            <div className={s.mainImg}>
              {isLoading ? (
                <Spinner animation="border" role="status" className="d-block mx-auto mt-5">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : error ? (
                <Alert variant="danger" className="text-center">
                  Ошибка загрузки слайдов!
                </Alert>
              ) : sliderItems ? (
                <Carousel fade>{sliderItems}</Carousel>
              ) : (
                <Alert variant="warning" className="text-center">
                  Нет доступных слайдов
                </Alert>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Slider;