import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAddProductsMutation } from "../../../../../api/ProductsApi";
import { IoIosCloseCircle } from "react-icons/io";
import { useGetCategoryQuery } from "../../../../../api/CategoryApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ProductForm() {
  const [addData] = useAddProductsMutation();
  const { data: categoryData } = useGetCategoryQuery();

  const [newProductName, setNewProductName] = useState({});
  const [newProductSubtitle, setNewProductSubtitle] = useState({});
  const [newProductText, setNewProductText] = useState({});
  const [newProductImage, setNewProductImage] = useState(null);
  const [newProductCategory, setNewProductCategory] = useState("");
  const [selectedLang] = useState("en");

  const showCustomAlert = (message, isError = false) => {
    const modalId = isError ? "customAlertFormEmptyInp" : "customAlertForm";
    const modal = document.getElementById(modalId);
    modal.style.display = "block";

    setTimeout(() => {
      modal.style.display = "none";
    }, 2000);
  };

  const addProduct = async (e) => {
    e.preventDefault();
  
  
    if (!newProductName[selectedLang] || !newProductCategory) {
      showCustomAlert("Empty Input", true);
      return;
    }
  
    const formData = new FormData();
    formData.append("title", JSON.stringify(newProductName));
    
    
    formData.append("subtitle", JSON.stringify(newProductSubtitle || {}));
    
    formData.append("description", JSON.stringify(newProductText));
    formData.append("categoryId", newProductCategory);
    
    if (newProductImage) formData.append("image", newProductImage);
  
    try {
      await addData(formData).unwrap();
      showCustomAlert("Data added successfully");
  
      setNewProductName({});
      setNewProductSubtitle({});
      setNewProductText({
        en: "",
        az: "",
        ru: "",
      });
      setNewProductImage(null);
      setNewProductCategory("");
    } catch (error) {
      showCustomAlert("Error occurred", true);
    }
  };
  

  return (
    <>
      <div id="customAlertForm" className="modal">
        <div className="modal-content">
          <span className="close">
            <IoIosCloseCircle />
          </span>
          <p>Data added successfully</p>
        </div>
      </div>

      <div id="customAlertFormEmptyInp" className="modal">
        <div className="modal-content bg-danger">
          <span className="close">
            <IoIosCloseCircle />
          </span>
          <p>Empty Input</p>
        </div>
      </div>

      <Form onSubmit={addProduct}>
        {["en", "az", "ru"].map((lang) => (
          <Form.Group className="form-group-custom" key={lang}>
            <Form.Label>Product Title ({lang.toUpperCase()})</Form.Label>
            <Form.Control
              type="text"
              value={newProductName[lang] || ""}
              onChange={(e) =>
                setNewProductName({ ...newProductName, [lang]: e.target.value })
              }
            />
            <Form.Label>Subtitle ({lang.toUpperCase()})</Form.Label>
            <Form.Control
              type="text"
              value={newProductSubtitle[lang] || ""}
              onChange={(e) =>
                setNewProductSubtitle({
                  ...newProductSubtitle,
                  [lang]: e.target.value,
                })
              }
            />
            <Form.Label>Product Description ({lang.toUpperCase()})</Form.Label>
            <ReactQuill
              value={newProductText[lang] || ""}
              onChange={(value) =>
                setNewProductText({ ...newProductText, [lang]: value })
              }
              theme="snow"
              style={{
                backgroundColor: "white",
                border: "1px solid #ccc",
              }}
            />
          </Form.Group>
        ))}

        <Form.Group className="form-group-custom">
          <Form.Label>Product Image</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setNewProductImage(e.target.files[0])}
          />
          {newProductImage && typeof newProductImage !== "object" && (
            <img
              src={`https://merit.emilgasarayev.com${newProductImage}`}
              alt="Current product"
              style={{ width: "100px", height: "100px", marginTop: "10px" }}
            />
          )}
        </Form.Group>

        <Form.Select
          onChange={(e) => setNewProductCategory(e.target.value)}
          value={newProductCategory}
          className="form-group-custom"
        >
          <option>Select category...</option>
          {categoryData?.categories?.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name?.[selectedLang] || item.name?.az || "Unknown"}
            </option>
          ))}
        </Form.Select>

        <Button type="submit">Add Product</Button>
      </Form>
    </>
  );
}

export default ProductForm;
