import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAddCategoryMutation } from "../../../../../api/CategoryApi";
import { IoIosCloseCircle } from "react-icons/io";

function CategoryForm() {
  const [addCategoryData] = useAddCategoryMutation();
  const [categoryAz, setCategoryAz] = useState("");
  const [categoryEn, setCategoryEn] = useState("");
  const [categoryRu, setCategoryRu] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const showCustomAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 2000);
  };

  const addCategory = async (e) => {
    e.preventDefault();

    if (!categoryAz || !categoryEn || !categoryRu) {
      showCustomAlert("Empty input fields!");
      return;
    }

    const newCategory = {
      name: {
        az: categoryAz,
        en: categoryEn,
        ru: categoryRu,
      },
    };

    try {
      await addCategoryData(newCategory).unwrap();
      showCustomAlert("Category added successfully!");
      setCategoryAz("");
      setCategoryEn("");
      setCategoryRu("");
    } catch (err) {
      showCustomAlert("Error adding category!");
    }
  };

  return (
    <>
      {showAlert && (
        <div className="modal show">
          <div className="modal-content">
            <span className="close" onClick={() => setShowAlert(false)}>
              <IoIosCloseCircle />
            </span>
            <p>{alertMessage}</p>
          </div>
        </div>
      )}

      <Form onSubmit={addCategory}>
        <Form.Group className="mb-3">
          <Form.Label>Category Name (AZ)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Kateqoriya adı (AZ)"
            value={categoryAz}
            onChange={(e) => setCategoryAz(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Category Name (EN)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Category name (EN)"
            value={categoryEn}
            onChange={(e) => setCategoryEn(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Category Name (RU)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Название категории (RU)"
            value={categoryRu}
            onChange={(e) => setCategoryRu(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Category
        </Button>
      </Form>
    </>
  );
}

export default CategoryForm;
