import React, { useEffect, useState } from 'react';
import { Chart } from 'chart.js/auto';
import { useGetProductsQuery } from '../../../../api/ProductsApi';
import { useGetCategoryQuery } from '../../../../api/CategoryApi';
import "./style.css"

const Analithing = () => {
  
  const { data: productsData, isLoading: productsLoading, error: productsError } = useGetProductsQuery();
  const { data: categoriesData, isLoading: categoriesLoading, error: categoriesError } = useGetCategoryQuery();

  
  const [chartConfig, setChartConfig] = useState(null);

  
  const categoryCounts = categoriesData?.categories?.reduce((acc, category) => {
    const categoryName = category.name.az; 
    acc[categoryName] = 0; 
    return acc;
  }, {});

  
  productsData?.forEach((product) => {
   
    if (product.categoryId && product.categoryId.name) {
      const categoryName = product.categoryId.name.az; 
      if (categoryCounts[categoryName] !== undefined) {
        categoryCounts[categoryName] += 1; 
      }
    }
  });

  const chartData = {
    labels: Object.keys(categoryCounts || {}),
    datasets: [{
      label: 'Məhsul Sayı',
      data: Object.values(categoryCounts || {}), 
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(75, 192, 192)',
        'rgb(255, 205, 86)',
        'rgb(201, 203, 207)',
        'rgb(54, 162, 235)',
      ],
    }],
  };

  const config = {
    type: 'doughnut',
    data: chartData,
  };

  useEffect(() => {
    if (productsData && categoriesData) {
      setChartConfig(config); 
    }
  }, [productsData, categoriesData]);

  useEffect(() => {
    if (chartConfig) {
      const ctx = document.getElementById('productChart').getContext('2d');
      new Chart(ctx, chartConfig); 
    }
  }, [chartConfig]);
  

  if (productsLoading || categoriesLoading) return <div>Loading...</div>;
  if (productsError || categoriesError) return <div>Error fetching data</div>;

  return (
    <div className='analithing_chart'>
      <canvas id="productChart" className='product_chart'></canvas>
    </div>
  );
};

export default Analithing;