import { baseQuery } from "./api";

export const FilesApi = baseQuery.injectEndpoints({
  tagTypes: ["Files"],
  endpoints: (builder) => ({
    getFiles: builder.query({
      query: () => ({
        url: "api/files",
        headers: {
          Accept: "application/json",
        },
      }),
      providesTags: ["Files"],
    }),
    addFile: builder.mutation({
      query: (file) => ({
        url: "api/files",
        method: "POST",
        body: file,
      }),
      invalidatesTags: ["Files"],
    }),
    deleteFile: builder.mutation({
      query: (fileId) => ({
        url: `api/files/${fileId}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["Files"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFilesQuery,
  useAddFileMutation,
  useDeleteFileMutation,
} = FilesApi;
