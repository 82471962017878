import React from "react";
import Slider from "./Slider/Slider";
import Section from "./Section/Section";
import ContactFooter from "../AboutUs/ContactFooter/ContactFooter";

function Home() {
  return (
    <>
      <Slider />
      <Section />
      <ContactFooter />
    </>
  );
}

export default Home;
