import React from 'react'
import Login from '../../components/AdminComponents/Login/index'

function LoginPage() {
  return (
    <>
      <Login/>
    </>
  )
}

export default LoginPage
