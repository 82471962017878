import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { FaRegEdit } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "../../HomeSection/HomeSectionTable/style.css";
import {
  useGetSectionQuery,
  useUpdateSectionMutation,
} from "../../../../../api/HomeSection";

function AboutTable() {
  const languages = ["az", "en", "ru"];

  
  const { data: section5Data, refetch: refetchSection5 } = useGetSectionQuery({ key: "section5", lang: "az" });
  const { data: section6Data, refetch: refetchSection6 } = useGetSectionQuery({ key: "section6", lang: "az" });
  const { data: section7Data, refetch: refetchSection7 } = useGetSectionQuery({ key: "section7", lang: "az" });

  const [updateSection] = useUpdateSectionMutation();
  const [show, setShow] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [newSectionTitle, setNewSectionTitle] = useState("");
  const [newSectionImage, setNewSectionImage] = useState(null);
  const [newSectionDescription, setNewSectionDescription] = useState("");
  const [selectedLang, setSelectedLang] = useState("az");

  const handleClose = () => {
    setShow(false);
    setNewSectionTitle("");
    setNewSectionImage(null);
    setNewSectionDescription("");
  };

  const handleShow = (section, lang) => {
    if (section.translations[lang]) {
      setSelectedSection(section);
      setSelectedLang(lang);
      setNewSectionTitle(section.translations[lang].title || "");
      setNewSectionDescription(section.translations[lang].description || "");
      setShow(true);
    } else {
      alert("Bu bölmə üçün tərcümə mövcud deyil!");
    }
  };

  
  const handleSaveChanges = async () => {
    if (!selectedSection) {
      alert("Bölmə düzgün seçilməyib");
      return;
    }

    const { key, translations } = selectedSection; 
    const formData = new FormData();
    formData.append("key", key); 
    formData.append(
      "translations",
      JSON.stringify({
        ...translations,
        [selectedLang]: {
          title: newSectionTitle,
          description: newSectionDescription,
        },
      })
    );
    if (newSectionImage) {
      formData.append("image", newSectionImage);
    }

    try {
      
      await updateSection({ key, data: formData }).unwrap();
      
      if (selectedSection.key === "section5") {
        refetchSection5();
      } else if (selectedSection.key === "section6") {
        refetchSection6();
      } else if (selectedSection.key === "section7") {
        refetchSection7();
      }

      handleClose();
    } catch (err) {
      console.error("Xəta! Bölmə yenilənə bilmədi.", err);
    }
  };

  return (
    <>
      
      <Modal show={show} onHide={handleClose} backdrop={false} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Bölməni Redaktə Et</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicTitle">
              <Form.Label>Bölmə Başlığı</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bölmə başlığı"
                value={newSectionTitle}
                onChange={(e) => setNewSectionTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Bölmə Şəkli</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setNewSectionImage(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
              <Form.Label>Bölmə Təsviri</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bölmə təsviri"
                value={newSectionDescription}
                onChange={(e) => setNewSectionDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Bağla
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Yadda saxla
          </Button>
        </Modal.Footer>
      </Modal>

      
      {[section5Data, section6Data, section7Data].map((section, index) => {
        if (!section) return null;

        return (
          <div key={index} className="table_section">
            <Table bordered hover>
              <thead className="table_heading">
                <tr>
                  <h2>About Section</h2>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Language</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Image</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {languages.map((lang) => {
                  const langName =
                    lang === "az"
                      ? "Azərbaycan"
                      : lang === "en"
                      ? "English"
                      : "Русский";
                  return (
                    <tr key={lang}>
                      <td>{langName}</td>
                      <td>
                        {section.translations[lang]?.title || "Məlumat yoxdur"}
                      </td>

                      <td>
                        {section.translations[lang]?.description ||
                          "Məlumat yoxdur"}
                      </td>

                      <td>
                        {section.image ? (
                          <img
                            src={`https://merit.emilgasarayev.com${section.image}`}
                            alt={section.translations[lang]?.title}
                            width="100"
                          />
                        ) : (
                          "Şəkil yoxdur"
                        )}
                      </td>
                      <td className="align-middle text-center">
                        <button
                          onClick={() => handleShow(section, lang)}
                          className="btn_product btn_product_edit"
                        >
                          <FaRegEdit />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        );
      })}
    </>
  );
}

export default AboutTable;
