import React from "react";
import { Link } from "react-router-dom";
import "./style.css";


function DashboardSidebar() {
  return (
    <>
      <section className="dashboardSidebar">
    
            <div>
              <div className="sidebar_topic">
              <div className="sidebar_category">
                  <Link to="/admin/dashboard/Home">
                    <p>Home</p>
                  </Link>
                </div>
                <div className="sidebar_product">
                  <Link to="/admin/dashboard/product">
                    <p>Product</p>
                  </Link>
                </div>
                <div className="sidebar_category">
                  <Link to="/admin/dashboard/category">
                    <p>Category</p>
                  </Link>
                </div>
                <div className="sidebar_category">
                  <Link to="/admin/dashboard/about">
                    <p>About</p>
                  </Link>
                </div>
                <div className="sidebar_category">
                  <Link to="/admin/dashboard/certificate">
                    <p>Certificate</p>
                  </Link>
                </div>
                <div className="sidebar_category">
                  <Link to="/admin/dashboard/slider">
                    <p>Slider</p>
                  </Link>
                </div>
                <div className="sidebar_category">
                  <Link to="/admin/dashboard/analithing">
                    <p>Analithing</p>
                  </Link>
                </div>
              </div>
            </div>
       
      </section>
    </>
  );
}

export default DashboardSidebar;
