import React from "react";
import DashboardSidebar from "../../components/AdminComponents/DashboardSidebar";
import DashboardProductBody from "../../components/AdminComponents/DashboardSidebar/DashboardProductBody";
import { Route, Routes } from "react-router-dom";
import DashboardCategory from "../../components/AdminComponents/DashboardSidebar/DashboardCategory";
import Logo from "../../img/logo.png";
import "../../components/AdminComponents/DashboardSidebar/style.css";
import About from "../../components/AdminComponents/DashboardSidebar/About";
import DashboardSlider from "../../components/AdminComponents/DashboardSidebar/Slider";
import Analithing from "../../components/AdminComponents/DashboardSidebar/Analithing";
import HomeSectionTable from "../../components/AdminComponents/DashboardSidebar/HomeSection/HomeSectionTable";
import Certificate from "../../components/AdminComponents/DashboardSidebar/Certificate";
import "./style.css"

function Dashboard() {
  return (
    <>
      <div className="container-fluid">
        <div className="row dashboardSidebar">
          <div className="admin_logo">
            <img src={Logo} alt="merit-brand logo" />
            <div className="marquee">
              <p>
                Şəkilləri yükləyərkən .webp formatında yükləyin.{" "}
                <a target="_blank" rel="noopener noreferrer" href="https://cloudconvert.com/png-to-webp">Linkə</a>{" "}
                click-ləyərək səhifəyə keçid edib png və ya pdf formatından webp
                formatına çevirə bilərsiniz.
              </p>
            </div>
          </div>
          <div className="col-2 p-0">
            <DashboardSidebar />
          </div>
          <div className="col-10 p-0 dashboard_body_content">
            <Routes>
              <Route path="home" element={<HomeSectionTable />} />
              <Route path="product" element={<DashboardProductBody />} />
              <Route path="category" element={<DashboardCategory />} />
              <Route path="about" element={<About />} />
              <Route path="certificate" element={<Certificate />} />
              <Route path="slider" element={<DashboardSlider />} />
              <Route path="analithing" element={<Analithing />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
